import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import GreenHeading from "../components/heading"
import Hero from "../components/hero/hero"
import Services from "../components/services/services"
import Fleet from "../components/fleet/fleet"
import Reasons from "../components/reasons/reasons"
import Reviews from "../components/reviews/reviews"
import Download from "../components/download"
import Locations from "../components/locations/locations"
import Content from "../components/subpage/content"
import { GeneralSchema } from "@ashleynexvelsolutions/general-schema"
import { PageSpecificSchema } from "@ashleynexvelsolutions/page-specific-schema"
import {
  GeneralSchemaFunction,
  PageSpecificSchemaFunction,
} from "../DataImports"
import TravelDestinations from "../components/TravelDestinations"
import { useTravelDestinationsQuery } from "../components/hooks/use-travel-destinations-query"

const ServicesPage = ({ data }) => {
  const travelDestinations = useTravelDestinationsQuery()
  const image =
    data.wpgraphql.page.seo !== null &&
    data.wpgraphql.page.seo.opengraphImage !== null
      ? data.site.siteMetadata.siteUrl +
        data.wpgraphql.page.seo.opengraphImage.imageFile.childImageSharp.fixed
          .src
      : "/blank.jpg"
  const title = data.subpage.title

  return (
    <>
      <GeneralSchema {...GeneralSchemaFunction()} />
      <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />

      <Layout>
        <Helmet>
          <title>{`${data.site.siteMetadata.title} | ${data.wpgraphql.page.seo.title}`}</title>
          <meta
            name="description"
            content={data.wpgraphql.page.seo.opengraphDescription}
          />
          <meta
            property="og:url"
            content={data.wpgraphql.page.seo.opengraphUrl}
          />
          <meta
            property="og:site_name"
            content={data.wpgraphql.page.seo.opengraphSiteName}
          />
          <meta property="og:title" content={data.wpgraphql.page.seo.title} />
          <meta
            property="og:description"
            content={data.wpgraphql.page.seo.opengraphDescription}
          />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Twitter */}
          <meta
            name="twitter:title"
            content={data.wpgraphql.page.seo.twitterTitle}
          />
          <meta
            name="twitter:description"
            content={data.wpgraphql.page.seo.twitterDescription}
          />

          <meta name="geo.region" content="US-PA" />
          <meta name="geo.placename" content="King of Prussia" />
          <meta name="geo.position" content="40.091710;-75.346160" />
          <meta name="ICBM" content="40.091710, -75.346160" />
        </Helmet>
        <Hero
          bannerData={{
            desktop: data.subpage.acf.banner_desktop,
            mobile: data.subpage.acf.banner_mobile,
          }}
        >
          <h1 className="heading sub">
            <div className="first">For</div>
            <GreenHeading size="6rem" text="Every" animate={false} />
            <div className="last">Occasion</div>
          </h1>
        </Hero>
        <Content
          data={data.subpage}
          sectionName="our-services"
          qaData={
            data.wpgraphql.page.nexvelschemapagesposts.questionsAndAnswers
          }
        />
        <Services
          preActive={
            data.wpgraphql.page.servicesPageActiveCategory.activeCategory.name
          }
        />
        <Fleet />
        <TravelDestinations data={travelDestinations} />
        <Reasons />
        <Reviews />
        <Download text={data.home.acf.download_text} />
        <Locations text={data.home.acf.locations_text} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query($path: String!, $id: ID!) {
    subpage: wordpressPage(path: { eq: $path }) {
      title
      path
      acf {
        banner_desktop {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        banner_mobile {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        content_1
        content_2
        content_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    home: wordpressPage(title: { eq: "Home" }) {
      acf {
        client_reviews_text
        download_text
        welcome_text
        locations_text
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    wpgraphql {
      page(id: $id, idType: DATABASE_ID) {
        seo {
          title
          opengraphDescription
          opengraphUrl
          opengraphSiteName
          twitterTitle
          twitterDescription
          opengraphImage {
            sourceUrl
            imageFile {
              childImageSharp {
                fixed {
                  src
                }
              }
            }
          }
        }
        nexvelschemapagesposts {
          videos {
            title
            url
            description
            thumbnailImage {
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
            uploadDate
          }
          questionsAndAnswers {
            question
            answer
          }
          maps {
            mapUrl
          }
          digitaldocuments {
            title
          }
          images {
            image {
              date
              description
              title
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
          }
          areaServedLandingPageSchema
          serviceSchema {
            serviceNameSchema
            linkToRelevantServicePageSchema
          }
          keywordsSchema {
            keywordSchema
          }
          localAwardsSchema {
            localAwardSchema
          }
          latitudeSchema
          longitudeSchema
          relevantExternalPagesSchema {
            relevantExternalPageSchema
          }
        }
        servicesPageActiveCategory {
          activeCategory {
            name
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        cities {
          city
        }
        services {
          service
          link_to_relevant_page_on_your_website
        }
      }
    }
  }
`

export default ServicesPage
